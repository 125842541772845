import React,{ useEffect } from 'react'
import logo from 'assets/images/tegut_Logo_Claim.png'
import bgBlue from 'assets/images/backgrounds/Background_blue@2x.jpg'

const Mandala = () => {
	useEffect(() => {
		function jsResponsiveScale(settings) {
			let target = settings.element,
				parentWidth = settings.parentwidth,
				parentHeight = settings.parentheight,
				targetWidth = target.offsetWidth,
				targetHeight = target.offsetHeight,
				scale = Math.min(parentWidth / targetWidth, parentHeight / targetHeight);

			target.classList.add('js-scale');

			if ((scale > settings.maxscale) && (isNaN(settings.maxscale) === false)) {
				scale = settings.maxscale;
			}

			// target.setAttribute("style", "-webkit-transform: translate(-50%, -50%) scale(" + scale + "); -ms-transform: translate(-50%, -50%) scale(" + scale + "); transform: translate(-50%, -50%) scale(" + scale + ");");
		}

		function resizeTestElement() {
			let target = document.querySelector('.circle-container'),
				parent = document.querySelector('.container__big');

			jsResponsiveScale({
				element: target,
				parentwidth: parent.offsetWidth,
				parentheight: parent.offsetHeight,
				maxscale: 0.8
			})
		}

		window.addEventListener('resize', () => {
			resizeTestElement();
		}, 250);

		window.addEventListener('load', () => {
			resizeTestElement();
		}, 250);

		window.addEventListener('orientationchange', () => {
			resizeTestElement();
		});
	});

	return (
		<div id="capture" className="container container__big" style={{
			backgroundImage: `url(${bgBlue})`,
			backgroundSize: `cover`,
			backgroundRepeat: `no-repeat`,
			transition: `all 3ms ease-out`,
		}}>
			<header>
				<h1 className="custom-headline">Mein Umfeld ist meine Verantwortung.</h1>
			</header>
			<h1 className="alert-text">Bitte wechsel auf ein Tablet.</h1>
			<ul className="circle-container circle-container__first">
				<li className="size size__l dz1" data-clonename="8"></li>
				<li className="size size__m dz2" data-clonename="11"></li>
				<li className="size size__l dz3" data-clonename="9"></li>
				<li className="size size__m dz4" data-clonename="10"></li>
				<li className="size size__l dz5" data-clonename="8"></li>
				<li className="size size__m dz6" data-clonename="11"></li>
				<li className="size size__l dz7" data-clonename="9"></li>
				<li className="size size__m dz8" data-clonename="10"></li>
				<li className="size size__l dz9" data-clonename="8"></li>
				<li className="size size__m dz10" data-clonename="11"></li>
				<li className="size size__l dz11" data-clonename="9"></li>
				<li className="size size__m dz12" data-clonename="10"></li>
				<li className="size size__l dz13" data-clonename="8"></li>
				<li className="size size__m dz14" data-clonename="11"></li>
				<li className="size size__l dz15" data-clonename="9"></li>
				<li className="size size__m dz16" data-clonename="10"></li>
				<li className="size size__l dz17" data-clonename="8"></li>
				<li className="size size__m dz18" data-clonename="11"></li>
				<li className="size size__l dz19" data-clonename="9"></li>
				<li className="size size__m dz20" data-clonename="10"></li>

				<ul className="circle-container circle-container__second">
					<li className="size size__m dz21" data-clonename="7"></li>
					<li className="size size__l dz22" data-clonename="4"></li>
					<li className="size size__m dz23" data-clonename="6"></li>
					<li className="size size__l dz24" data-clonename="5"></li>
					<li className="size size__m dz25" data-clonename="7"></li>
					<li className="size size__l dz26" data-clonename="4"></li>
					<li className="size size__m dz27" data-clonename="6"></li>
					<li className="size size__l dz28" data-clonename="5"></li>
					<li className="size size__m dz29" data-clonename="7"></li>
					<li className="size size__l dz30" data-clonename="4"></li>
					<li className="size size__m dz31" data-clonename="6"></li>
					<li className="size size__l dz32" data-clonename="5"></li>

					<ul className="circle-container circle-container__third">
						<li className="size size__s dz33" data-clonename="3"></li>
						<li className="size size__m dz34" data-clonename="2"></li>
						<li className="size size__s dz35" data-clonename="3"></li>
						<li className="size size__m dz36" data-clonename="1"></li>
						<li className="size size__s dz37" data-clonename="3"></li>
						<li className="size size__m dz38" data-clonename="2"></li>
						<li className="size size__s dz39" data-clonename="3"></li>
						<li className="size size__m dz40" data-clonename="1"></li>

						<ul className="circle-container circle-container__fourth">
							<li className="size size__xl dz41" data-clonename="center"></li>
						</ul>
					</ul>
				</ul>
			</ul>
			<div className="logo-container">
				<img
					alt="tegut... gute Lebensmittel"
					src={ logo }
					className="logo"
				/>
			</div>
		</div>
	)
};

export default Mandala;
