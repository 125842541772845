import React from 'react'
import 'assets/style/app.scss'
import Generator from "../components/Generator"
import Mandala from "../components/Mandala"
import iconClose from 'assets/images/close.svg'

const siteTitle = {
	title: 'tegut - Umfeld-Generator',
}

const isBrowser = typeof document !== "undefined"

function close() {
	if(isBrowser) {
		console.log('clicked')
		document.querySelector('.canvas-wrapper').classList.remove('is-open');
		document.querySelector('body').classList.remove('is-capture');
		document.querySelector('.screenshot').remove();
	}
}

const IndexPage = () => {
	return (
		<main className="main">
			<title>{siteTitle.title}</title>
			<Generator />
			<Mandala />
			<div className="canvas-wrapper">
				<img src={iconClose} className="close" onClick={close} />
			</div>
		</main>
	)
};

export default IndexPage;
