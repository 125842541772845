import React, {useEffect, useRef} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'

import TweenMax, {gsap} from 'gsap'
import Draggable from 'gsap/Draggable'
import html2canvas from 'html2canvas'


import bgBlue from 'assets/images/backgrounds/Background_blue@2x.jpg'
import bgGreen from 'assets/images/backgrounds/Background_green@2x.jpg'
import bgLime from 'assets/images/backgrounds/Background_lime@2x.jpg'
import bgOrange from 'assets/images/backgrounds/Background_orange@2x.jpg'
import bgPink from 'assets/images/backgrounds/Background_pink@2x.jpg'
import bgPurple from 'assets/images/backgrounds/Background_purple@2x.jpg'
import bgTurquoise from 'assets/images/backgrounds/Background_turquoise@2x.jpg'
import bgYellow from 'assets/images/backgrounds/Background_yellow@2x.jpg'

const entryText = {
	headline: "Umfeld-Generator",
	text: "tegut... ist umfeldbewusst! Du bist auch umfeldbewusst? Zeige  was Dir wichtig ist und kreiere Dein eigenes Umfeld. Übrigens: Dein Bild kannst Du ganz einfach runterladen und überall verwenden wo Du es willst."
}

const Generator = () => {

	const images = useStaticQuery(graphql`
		query HeaderQuery {
			allFile {
				edges {
					node {
						publicURL
						relativePath
						childImageSharp {
						id
						fluid {
							base64
							tracedSVG
							srcWebp
							srcSetWebp
							originalImg
							originalName
						}
					  }
					}
				}
			}
		}
	`)

	// console.log(images.allFile.edges)

	const mainEl = useRef(null);
	const isBrowser = typeof document !== "undefined"

	function initAcc(elem, option) {

		if (isBrowser) {
			document.addEventListener('click', (e) => {
				if (!e.target.matches(elem+' .a-btn')) return;
				else {
					if(!e.target.parentElement.classList.contains('active')) {
						if(option === true) {
							const elementList = document.querySelectorAll(elem +' .a-container');
							Array.prototype.forEach.call(elementList, (e) => {
								e.classList.remove('active');
							});
						}
						e.target.parentElement.classList.add('active');
					} else {
						e.target.parentElement.classList.remove('active');
					}
				}
			});
		}
	}

	initAcc('.accordion', true);

	/**
	 * Remove the image if its in a specified circle there
	 * @param circle
	 */
	function removeInnerCircleImage(circle) {
		let childImage = circle.querySelector('img');
		if (null !== childImage) {
			childImage.remove();
		}
	}

	useEffect(() => {
		//https://greensock.com/docs/v3/Plugins/Draggable
		//https://codepen.io/osublake/pen/XJQKVX
		gsap.registerPlugin(Draggable);
		gsap.registerPlugin(TweenMax);

		const droppables = document.querySelectorAll('.icon-drag');

		TweenMax.to(droppables, 0.1, {
			x: 0,
			y: 0
		});

		let myClone = null;
		Draggable.create(droppables, {
			bounds: window,
			onDrag: function (e) {
				if (myClone === null) {
					myClone = e.target.cloneNode();
				}
			},
			onDragEnd: function (e) {
				for (let i = 1; i < 42; i++) {
					let container = '.size.dz' + i;
					if (this.hitTest(container, 20)) {
						let dropCircle = document.querySelector(container);
						let cloneName = dropCircle.getAttribute('data-clonename');
						let siblings = document.querySelectorAll('.circle-container [data-clonename="' + cloneName + '"]');
						siblings.forEach((elem) => {
							removeInnerCircleImage(elem);
							elem.append(myClone.cloneNode());
						});
					}
				}
				TweenMax.to(this.target, 0.2, {
					x: 0,
					y: 0
				});
				myClone = null;
			}
		});

	}, []);

	function MyGatsbyImage(props) {
		if (typeof document !== "undefined") {
			return <GatsbyImage image={props.image} src={props.image.node.publicURL} alt={props.image.node.childImageSharp.fluid.originalName} draggable="true" className="icon-drag"/>
		}
		return '';
	}

	function cumstomText() {
		if(isBrowser) {
			const customTextVal = document.querySelector('.custom-text-field').value;

			document.querySelector('.custom-headline').innerHTML = customTextVal;
		}
	}

	function customBackground(e) {
		if(isBrowser) {
			const imgSrc = e.target.src;
			document.querySelector('.container__big').style.backgroundImage = 'url(' + imgSrc + ')';
		}
	}

	function screenshot() {
		if(isBrowser) {
			const capture = document.querySelector('.main');
			const captureWrapper = document.querySelector('.canvas-wrapper');

			const options = {
				backgroundColor: null,
				removeContainer: true,
				allowTaint: true,
				foreignObjectRendering: false,
				style: {
					left: '0',
					right: '0',
					bottom: '0',
					top: '0'
				},
				x: 0,
				y: 0,
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
				logging: true,
				ignoreElements: (element) => {
					return element.className === 'container container__small ignore';
				},
			};

			html2canvas(capture, options).then(canvas => {
				captureWrapper.appendChild(canvas)
				canvas.classList.add('screenshot');
				captureWrapper.classList.add('is-open');
				document.querySelector('body').classList.add('is-capture');

				// auto download function
				let link = document.createElement('a');
				link.download = 'mein-tegut-mandala_' + new Date().getTime() + '.png';
				link.href = canvas.toDataURL();
				link.click();
			});
		}
	}

	return (
		<div className="container container__small ignore" ref={mainEl}>
			<header>
				<h1>{entryText.headline}</h1>
				<p>{entryText.text}</p>
			</header>
			<div className="accordion">
				<div className="a-container">
					<p className="a-btn">Hintergrund-Auswahl</p>
					<div className="a-panel">
						<ul className="bg-options">
							<li onClick={customBackground}><img src={bgBlue} alt="Blue" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgGreen} alt="Green" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgLime} alt="Lime" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgOrange} alt="Orange" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgPink} alt="Pink" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgPurple} alt="Purple" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgTurquoise} alt="Turquoise" className="customBg" draggable={false} /></li>
							<li onClick={customBackground}><img src={bgYellow} alt="Yellow" className="customBg" draggable={false} /></li>
						</ul>
					</div>
				</div>
				<div className="a-container">
					<p className="a-btn">Icons</p>
					<div className="a-panel">
						<p>
							(Drag & Drop)
						</p>
						<div className="scroll scrollbar space-s">
							{images.allFile.edges.map((image, i) => (
								<MyGatsbyImage image={image} key={i} />
							))}
						</div>
					</div>
				</div>
				<div className="a-container">
					<p className="a-btn">Dein Text</p>
					<div className="a-panel">
						<p>
							Du hast etwas zu sagen? Sehr gut! Schreibe eine kurze Headline wie Dein Umfeld ist und was es Dir bedeutet.
						</p>
						<div className="cumstom-text space-s">
							<input type="text" name="Dein Text" placeholder="Dein Text" className="custom-text-field" maxLength={55} /><br />
							<button className="btn btn__submit" onClick={cumstomText}>
								Bestätigen
							</button>
						</div>
					</div>
				</div>
			</div>
			<button className="btn btn__generate" onClick={screenshot}>
				Dein Umfeld-Motiv herunterladen
			</button>
		</div>
	)
}

export default Generator;
